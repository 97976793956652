var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('Row', [_c('Col', {
    attrs: {
      "span": "24"
    }
  }, [_c('FilterTable', {
    attrs: {
      "name": "domains",
      "container": true,
      "fetch-items": this.vendorGetVendorDomains,
      "allow-download": false,
      "fetch-items-params": _vm.qs ? {
        scopeProperty: _vm.scopeSelectedProperty.id,
        period: _vm.scopeSelectedDaterange
      } : {},
      "default-paging": {
        sort: 'Vendordomains.created',
        direction: 'desc'
      },
      "namespace": "vendorDomains",
      "columns": _vm.columns,
      "limit": 20,
      "search-filters": ['search', 'domainLevel', 'onlyOrphans']
    }
  })], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }