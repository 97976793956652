<template>
  <Row>
    <Col span="24">
      <FilterTable
        name="domains"
        :container="true"
        :fetch-items="this.vendorGetVendorDomains"
        :allow-download="false"
        :fetch-items-params="qs ? {scopeProperty: scopeSelectedProperty.id, period: scopeSelectedDaterange} : {}"
        :default-paging="{sort: 'Vendordomains.created', direction: 'desc' }"
        namespace="vendorDomains"
        :columns="columns"
        :limit="20"
        :search-filters="['search', 'domainLevel', 'onlyOrphans']"
      />
    </Col>
  </Row>
</template>

<script>
import {mapActions, mapGetters} from 'vuex'
import FilterTable from '@/components/ui/FilterTable'
import tableColumn from '@/mixins/tableColumn'
import pageConfig from '@/mixins/pageConfig'

export default {
  name: 'AdminUserIndex',
  components: {FilterTable},
  mixins: [
    tableColumn,
    pageConfig
  ],
  data () {
    return {
      columns: [
        this.createColumn(this.$t('global.table.name'), null, 'Vendordomains.domain_pattern', row => ({
          tag: 'strong',
          content: row.domain_pattern.replace(/\\/g, '').replace(/\$/g, '')
        })),
        this.createColumn(this.$t('global.table.vendor'), null, 'Vendordomains.vendor_id', row => row.vendor_id ? {
          tag: 'router-link',
          props: {to: `/vendors/${row.vendor.uuid}`, tag: 'a'},
          content: row => ({tag: 'strong', content: row.vendor.name})
        } : {
          tag: 'router-link',
          props: {to: `/admin/domains/view?id=${row.id}`, tag: 'a'},
          classes: 'missing-content',
          content: () => ({tag: 'strong', content: this.$t('global.vendor.novendor')})
        }),
        this.createColumn(this.$t('global.table.level'), 'level', 'Vendordomains.level'),
        this.createColumn(this.$t('global.table.origin'), 'origin', 'Vendordomains.origin'),
        this.createColumn(this.$t('global.table.created'), 'created', 'Vendordomains.created', 'date'),
        this.createColumn(this.$t('global.table.modified'), 'modified', 'Vendordomains.modified', 'date'),
        this.createColumn(this.$t('global.table.manage'), null, null, row => ({
          tag: 'Button',
          props: {type: 'primary', to: `/admin/domains/view?id=${row.id}`},
          classes: 'btn btn--elevated',
          content: this.$t('global.table.manage')
        }))
      ]
    }
  },
  methods: {
    ...mapActions({
      vendorGetVendorDomains: 'vendor/getVendorDomains'
    })
  },
  computed: {
    ...mapGetters({
      scopeSelectedProperty: 'scope/selectedProperty',
      scopeSelectedDaterange: 'scope/selectedDaterange',
      qs: 'scope/qs'
    })
  }
}
</script>

<style scoped>

</style>
